// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'preview-etisalat',
  host: 'atlaspreview.com',

  hedwigUrl: 'https://hedwig.atlaspreview.com',
  saharaUrl: 'https://my.atlaspreview.com',
  instructorMarketUrl: 'https://instructors.atlaspreview.com',
  serviceMarketUrl: 'https://instructors.atlaspreview.com',
  integrationFramework: 'https://integration.atlaspreview.com',
  amberBaseUrl: 'https://amber.atlaspreview.com',
  amberUrl: 'https://amber.atlaspreview.com/api',
  bouncerUrl: 'https://account.atlaspreview.com',
  sphinxUrl: 'https://downloads.atlaspreview.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAWLQHGLXHOBFYNGJW',
    s3Url: 'https://downloads.atlaspreview.com/proxy/service/jwt',
    cdnBucket: 'leap-preview-02-cdn',
    uploadsBucket: 'leap-preview-02-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'test_ZVI4LE36HVE2FCXGOAHXRIADDA7XNF5K'
  },
  okta: {
    issuer: 'https://edcasteu.oktapreview.com/oauth2/aus3qjxzm45xSXp3S0x7',
    clientId: '00a-etisalatpreview-env',
    prefix: 'etisalatpreview'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
